<template>
  <div>
    <v-snackbar v-model="showSnackBar" color="primary" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: #ffffff">
            {{ msg }}
          </span>
        </v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #ffffff">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-center>
      <v-flex pt-5 xs11 sm11 md11 lg11 xl12>
        <!-- Header Section -->
        <v-layout wrap justify-start class="my-3">
          <v-flex
            xs12
            sm3
            md3
            lg12
            text-start
            align-center
            pt-2
            class="headingChurch"
            >Edit Church
          </v-flex>
        </v-layout>

        <!-- Card Section -->
        <v-layout wrap justify-center>
          <v-flex xs12>
            <v-card>
              <v-card-text>
                <v-form ref="editForm" v-model="formValid">
                  <v-layout wrap justify-start px-2>
                    <v-flex xs12>
                      <v-layout align-center>
                        <v-flex>
                          <span class="label"
                            >Church Name<span style="color: red">*</span></span
                          >
                        </v-flex>
                      </v-layout>
                      <v-text-field
                        class="pt-2 text-des"
                        style="font-size: 14px"
                        v-model="churchView.name"
                        append-icon="mdi-magnify"
                        :rules="nameRules"
                        @keyup.enter="showMapDialog"
                        @click:append="showMapDialog"
                        placeholder="Church name with location : ( St.Joseph's Church Koovappally )"
                        outlined
                        dense
                      ></v-text-field>
                    </v-flex>

                    <v-dialog v-model="mapDialog" max-width="800px" scrollable>
                      <v-card>
                        <v-card-title class="headline">Map</v-card-title>
                        <v-card-text>
                          <div ref="map" style="height: 400px"></div>
                        </v-card-text>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn text @click="mapDialog = false"
                            >Continue</v-btn
                          >
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                    <v-flex
                      xs12
                      sm4
                      md4
                      lg4
                      pr-lg-2
                      pr-sm-6
                      pr-md-6
                      pr-0
                      v-if="churchView.location"
                    >
                      <span class="label">
                        Latitude<span style="color: red">*</span>
                      </span>
                      <v-text-field
                        class="pt-2 text-des"
                        style="font-size: 14px"
                        :rules="latRules"
                        v-model="churchView.location.coordinates[1]"
                        outlined
                        dense
                      ></v-text-field>
                    </v-flex>

                    <v-flex
                      xs12
                      sm4
                      md4
                      lg4
                      pr-lg-2
                      pr-sm-6
                      pr-md-6
                      pr-0
                      v-if="churchView.location"
                    >
                      <span class="label">
                        Longitude<span style="color: red">*</span>
                      </span>
                      <v-text-field
                        class="pt-2 text-des"
                        style="font-size: 14px"
                        v-model="churchView.location.coordinates[0]"
                        :rules="lonRules"
                        outlined
                        dense
                      ></v-text-field>
                    </v-flex>

                    <v-flex
                      xs12
                      sm4
                      md4
                      lg4
                      pr-lg-2
                      pr-sm-0
                      pr-md-0
                      pr-0
                      v-if="churchView.address"
                    >
                      <span class="label">
                        Pin Code <span style="color: red">*</span></span
                      >
                      <v-text-field
                        class="pt-2 text-des"
                        style="font-size: 14px"
                        :rules="pinRules"
                        v-model="churchView.address.postalCode"
                        outlined
                        dense
                      ></v-text-field>
                    </v-flex>
                    <v-flex
                      xs12
                      sm3
                      md3
                      lg3
                      pr-lg-2
                      pr-sm-2
                      pr-md-2
                      pr-0
                      v-if="churchView.address"
                    >
                      <span class="label">
                        Street <span style="color: red">*</span></span
                      >
                      <v-text-field
                        class="pt-2 text-des"
                        style="font-size: 14px"
                        :rules="streetRules"
                        v-model="churchView.address.street"
                        outlined
                        dense
                      ></v-text-field>
                    </v-flex>
                    <v-flex
                      xs12
                      sm3
                      md3
                      lg3
                      pr-lg-2
                      pr-sm-2
                      pr-md-2
                      pr-0
                      v-if="churchView.address"
                    >
                      <span class="label">
                        City <span style="color: red">*</span></span
                      >
                      <v-text-field
                        class="pt-2 text-des"
                        style="font-size: 14px"
                        :rules="cityRules"
                        v-model="churchView.address.city"
                        outlined
                        dense
                      ></v-text-field>
                    </v-flex>
                    <v-flex
                      xs12
                      sm3
                      md3
                      lg3
                      pr-lg-2
                      pr-sm-2
                      pr-md-2
                      pr-0
                      v-if="churchView.address"
                    >
                      <span class="label">
                        State <span style="color: red">*</span></span
                      >
                      <v-text-field
                        class="pt-2 text-des"
                        style="font-size: 14px"
                        :rules="stateRules"
                        v-model="churchView.address.state"
                        outlined
                        dense
                      ></v-text-field>
                    </v-flex>
                    <v-flex
                      xs12
                      sm3
                      md3
                      lg3
                      pr-lg-2
                      pr-sm-2
                      pr-md-2
                      pr-0
                      v-if="churchView.address"
                    >
                      <span class="label">
                        Country<span style="color: red">*</span>
                      </span>
                      <v-text-field
                        class="pt-2 text-des"
                        style="font-size: 14px"
                        :rules="countryRules"
                        v-model="churchView.address.country"
                        outlined
                        dense
                      ></v-text-field>
                    </v-flex>

                    <!-- <v-flex xs12>
                      <v-divider class="mt-2"></v-divider>
                    </v-flex> -->

                    <v-flex xs12 sm3 md3 lg2 pr-lg-2 pr-sm-2 pr-md-2 pr-0>
                      <span class="label">
                        Rite <span style="color: red">*</span></span
                      >
                      <v-select
                        class="pt-2 text-des"
                        style="font-size: 14px"
                        :rules="riteRules"
                        outlined
                        dense
                        :items="riteArray"
                        v-model="churchView.rite"
                        item-text="name"
                        item-value="_id"
                      ></v-select>
                    </v-flex>
                    <v-flex xs12 sm3 md3 lg3 pr-lg-6 pr-sm-6 pr-md-6 pr-0>
                      <span class="label">
                        Diocese <span style="color: red">*</span></span
                      >
                      <v-select
                        class="pt-2 text-des"
                        style="font-size: 14px"
                        :rules="dioceseRules"
                        outlined
                        dense
                        :items="dioceseArray"
                        v-model="churchView.diocese"
                        item-text="name"
                        item-value="_id"
                      ></v-select>
                    </v-flex>

                    <v-flex xs12 sm3 md3 lg3 pr-lg-6 pr-sm-6 pr-md-6 pr-0>
                      <span class="label">
                        Parish Type <span style="color: red">*</span></span
                      >
                      <v-select
                        class="pt-2 text-des"
                        style="font-size: 14px"
                        :rules="parishRules"
                        outlined
                        dense
                        :items="parishArray"
                        item-text="name"
                        item-value="_id"
                        v-model="churchView.parishType"
                      ></v-select>
                    </v-flex>
                    <v-flex xs12 sm3 md3 lg4 pr-lg-2 pr-sm-2 pr-md-2 pr-0>
                      <span class="label">
                        Services <span style="color: red">*</span></span
                      >
                      <v-select
                        class="pt-2 text-des"
                        style="font-size: 14px"
                        :rules="serviceRules"
                        outlined
                        dense
                        :items="servicesArray"
                        v-model="churchView.services"
                        item-text="name"
                        item-value="name"
                        multiple
                      ></v-select>
                    </v-flex>

                    <v-flex xs12 sm6 md6 lg6 pr-lg-2 pr-sm-2 pr-md-2 pr-0>
                      <span class="label"> Phone </span>
                      <v-text-field
                        class="pt-2 text-des"
                        style="font-size: 14px"
                        :rules="phoneRules"
                        v-model="churchView.contactNumber"
                        outlined
                        dense
                      ></v-text-field>
                    </v-flex>

                    <v-flex xs12 sm6 md6 lg6 pr-lg-2 pr-sm-2 pr-md-2 pr-0 pt-0>
                      <span class="label"> Email </span>
                      <v-text-field
                        class="pt-2 text-des"
                        style="font-size: 14px"
                        :rules="emailRules"
                        v-model="churchView.email"
                        outlined
                        dense
                      ></v-text-field>
                    </v-flex>

                    <v-flex xs12 sm12 md12 lg12>
                      <span class="label">
                        Upload Image<span style="color: red">*</span>
                      </span>
                      <ImageComp
                        class="pt-2 text-des"
                        :singleImage="churchView.churchImage"
                        :rules="imgRules"
                        @stepper="winStepper"
                        :componentType="'courseImage'"
                      />
                    </v-flex>

                    <v-flex xs12 pt-3>
                      <span class="label"> Description </span>
                      <v-textarea
                        class="pt-2 text-des"
                        style="font-size: 14px"
                        v-model="churchView.description"
                        outlined
                        dense
                      ></v-textarea>
                    </v-flex>
                  </v-layout>
                </v-form>
              </v-card-text>
              <v-divider></v-divider>

              <v-card-actions class="py-4 justify-end headline grey lighten-2">
                <v-flex text-end px-3 px-lg-4 px-md-4 px-sm-4>
                  <v-btn color="primary" class="churchbtn" @click="edit()"
                    >SAVE CHURCH</v-btn
                  >
                </v-flex>
              </v-card-actions>
            </v-card>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>


<script
  src="https://maps.googleapis.com/maps/api/js?libraries=places&key=AIzaSyBpukNl1L1qGqEwad87A1-MkZj8H_bKDZM&libraries=visualization,drawing"
  async defer></script>

<script>
import ImageComp from "@/components/Common/singleImages";
import axios from "axios";
export default {
  components: {
    ImageComp,
  },
  data() {
    return {
      churchView: {},
      formValid: "",
      riteArray: [],
      languageArray: [],
      parishArray: [],
      dioceseArray: [],
      servicesArray: [
        { _id: "1", name: "Holy Mass" },
        { _id: "2", name: "Confession" },
        { _id: "3", name: "Adoration" },
      ],
      selectedServicesNames: [],
      churchImage: "",
      image: "",
      name: "",
      lat: "",
      lon: "",
      description: "",
      street: "",
      city: "",
      country: "",
      state: "",
      postalCode: "",
      contactNumber: "",
      email: "",
      mapDialog: false,
      diocese: "",
      parishType: "",
      rite: "",
      language: "",
      map: null,
      marker: null,
      geocoder: null,
      placesService: null,
      is24x7: false, // Add this line
      showSnackBar: false,
      timeout: 5000,
      msg: "",

      nameRules: [(v) => !!v || "Church Name is required"],
      latRules: [(v) => !!v || "Latitude is required"],
      lonRules: [(v) => !!v || "Longitude is required"],
      imgRules: [(v) => !!v || "Image is required"],

      pinRules: [(v) => !!v || "Pin code is required"],

      streetRules: [(v) => !!v || "Strret is required"],

      cityRules: [(v) => !!v || "City is required"],
      stateRules: [(v) => !!v || "State is required"],
      countryRules: [
        (v) => !!v || "Country is required",
        (v) => /^[A-Z]/.test(v) || "Country must start with a capital letter",
      ],

      riteRules: [(v) => !!v || "Rite is required"],
      parishRules: [(v) => !!v || "Parish Type is required"],
      dioceseRules: [(v) => !!v || "Diocese is required"],
      serviceRules: [
        (v) =>
          (Array.isArray(v) && v.length > 0) ||
          "At least one service is required",
      ],

      emailRules: [(v) => !v || /.+@.+\..+/.test(v) || "E-mail must be valid"],

      phoneRules: [
        (v) => !v || /^\d+$/.test(v) || "Phone number must contain only digits",
        (v) =>
          !v || v.length === 10 || "Phone number must be exactly 10 digits",
      ],
    };
  },
  mounted() {
    this.getData();

    this.getRite();
    this.getParish();
    this.getDiocese();
  },
  methods: {
    showMapDialog() {
      this.mapDialog = true;
      this.$nextTick(() => {
        this.initMap();
        this.fetchLocation(); // Fetch location when the dialog opens
      });
    },

    initMap() {
      this.map = new google.maps.Map(this.$refs.map, {
        center: { lat: 9.5916, lng: 76.5226 },
        zoom: 12,
      });
      this.marker = new google.maps.Marker({
        map: this.map,
      });

      this.geocoder = new google.maps.Geocoder();
    },

    fetchLocation() {
      if (!this.churchView.name) return;

      this.geocoder.geocode(
        { address: this.churchView.name },
        (results, status) => {
          if (status === google.maps.GeocoderStatus.OK && results.length > 0) {
            const place = results[0];
            const location = place.geometry.location;
            const addressComponents = place.address_components;

            this.churchView.location.coordinates = [
              location.lng(),
              location.lat(),
            ];
            this.churchView.address.street =
              addressComponents.find((component) =>
                component.types.includes("route")
              )?.long_name || "";
            this.churchView.address.city =
              addressComponents.find((component) =>
                component.types.includes("locality")
              )?.long_name || "";
            this.churchView.address.state =
              addressComponents.find((component) =>
                component.types.includes("administrative_area_level_1")
              )?.long_name || "";
            this.churchView.address.country =
              addressComponents.find((component) =>
                component.types.includes("country")
              )?.long_name || "";
            this.churchView.address.postalCode =
              addressComponents.find((component) =>
                component.types.includes("postal_code")
              )?.long_name || "";

            // Center the map and place the marker
            this.map.setCenter(location);
            this.map.setZoom(15);
            this.marker.setPosition(location);
          } else {
            alert(
              "Geocode was not successful for the following reason: " + status
            );
          }
        }
      );
    },

    getRite() {
      this.appLoading = true;
      axios({
        url: "/get/rite/list",
        method: "GET",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.riteArray = response.data.data;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    getDiocese() {
      this.appLoading = true;
      axios({
        url: "/get/diocese/list",
        method: "GET",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.dioceseArray = response.data.data;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    getParish() {
      this.appLoading = true;
      axios({
        url: "/get/parish/type/list ",
        method: "GET",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.parishArray = response.data.data;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getData() {
      this.appLoading = true;
      axios({
        url: "/get/church/info",
        method: "GET",
        params: {
          id: this.$route.query.id,
        },
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.churchView = response.data.data;
          // for (let i = 0; i < this.churchView.services.length; i++) {
          //   if (this.churchView.services[i] === "Confession") {
          //     this.checkCon = this.churchView.services[i];
          //   } else if (this.churchView.services[i] === "Holy Mass") {
          //     this.checkHoly = this.churchView.services[i];
          //   } else if (this.churchView.services[i] === "Adoration") {
          //     this.checkAdor = this.churchView.services[i];
          //   }
          // }
          //this.examDetails = response.data.examSettings;
          //this.isExam = response.data;
          // this.name = response.data.data.name;
          // this.categoryArray = response.data.data.category;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    winStepper(window_data) {
      if (window_data.type == "courseImage") {
        this.courseImage = window_data.selectedFiles;
      }
    },
    uploadImage(id) {
      let formData = new FormData();
      formData.append("id", id);
      formData.append("image", this.courseImage);

      axios({
        method: "POST",
        url: "/add/church/images",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.getData();
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.dialog = false;
            formData = new FormData();
            this.courseImage = "";
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    edit() {
      const form = this.$refs.editForm;

      if (form.validate()) {
        this.appLoading = true;
        var user = {
          id: this.churchView._id,
          name: this.churchView.name,
          postalCode: this.churchView.address.postalCode,
          street: this.churchView.address.street,
          city: this.churchView.address.city,
          state: this.churchView.address.state,
          country: this.churchView.address.country,
          email: this.churchView.email,
          contactNumber: this.churchView.contactNumber,
          lat: this.churchView.location.coordinates[1],
          lon: this.churchView.location.coordinates[0],
          parishType: this.churchView.parishType,
          rite: this.churchView.rite,
          diocese: this.churchView.diocese,
          services: this.churchView.services,
          desc: this.churchView.description,
        };

        axios({
          url: "/edit/church",
          method: "POST",
          data: user,
          headers: {
            token: localStorage.getItem("token"),
          },
        })
          .then((response) => {
            this.appLoading = false;
            if (response.data.status) {
              if (this.courseImage) {
                this.uploadImage(this.churchView._id);
              }
              this.$router.push({
                path: "/churchView",
                query: {
                  id: user.id,
                  msg: response.data.msg,
                },
              });
            } else {
              this.msg = response.data.msg;
              this.showSnackBar = true;
            }
          })
          .catch((err) => {
            this.appLoading = false;
            this.ServerError = true;
            console.log(err);
          });
      } else {
        this.msg = "Please fill out all fields correctly";
        this.showSnackBar = true;
      }
    },

    //     edit() {

    // const form = this.$refs.editForm;

    // if (form.validate()) {
    //       this.appLoading = true;
    //       var user = {};
    //       user["id"] = this.churchView._id;
    //       user["name"] = this.churchView.name;
    //       user["postalCode"] = this.churchView.address.postalCode;
    //       user["street"] = this.churchView.address.street;
    //       user["city"] = this.churchView.address.city;
    //       user["state"] = this.churchView.address.state;
    //       user["country"] = this.churchView.address.country;
    //       user["email"] = this.churchView.email;
    //       user["contactNumber"] = this.churchView.contactNumber;
    //       user["lat"] = this.churchView.location.coordinates[1];
    //       user["lon"] = this.churchView.location.coordinates[0];
    //       user["parishType"] = this.churchView.parishType;
    //       user["rite"] = this.churchView.rite;
    //       user["diocese"] = this.churchView.diocese;
    //       user["services"] = this.churchView.services;
    //       // user["street"] = this.churchView.feastDay;
    //       axios({
    //         url: "/edit/church",
    //         method: "POST",
    //         data: user,
    //         headers: {
    //           token: localStorage.getItem("token"),
    //         },
    //       })
    //         .then((response) => {
    //           this.appLoading = false;
    //           if (response.data.status) {
    //             if (this.courseImage) {
    //               this.uploadImage(this.churchView._id);
    //             }

    //             this.msg = response.data.msg;
    //             this.showSnackBar = true;
    //              this.$router.push("/churchView?id=" + user.id);

    //           } else {
    //             this.msg = response.data.msg;
    //             this.showSnackBar = true;
    //           }
    //         })
    //         .catch((err) => {
    //           this.appLoading = false;
    //           this.ServerError = true;
    //           console.log(err);
    //         });
    //     }
    //     else {
    //         this.msg = "Please fill out all fields correctly";
    //         this.showSnackBar = true;
    //     }
    //   },
    //   validateImage() {
    //     if (!this.courseImage) {
    //         this.msg = "Image is required.";
    //         this.showSnackBar = true;
    //         return false;
    //     }
    //     return true;
    // },
  },
};
</script>